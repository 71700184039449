.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: all 0.15s ease-out;
    .loader {
        width: 100px;
        height: 100px;
    }
    &.hidden {
        opacity: 0;
        pointer-events: none;
    }
}
